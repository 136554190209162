import styled from '@emotion/styled'
import React from 'react'
import BreakPoints from '../../styles/breakPoints'
import ColumnH1 from '../atoms/columnH1'
import Introduction004 from './Introduction004'
import Introduction005 from './Introduction005'
import Introduction006 from './Introduction006'
import Introduction007 from './Introduction007'
import Introduction008 from './Introduction008'
import Introduction009 from './Introduction009'
import Introduction010 from './Introduction010'
import Introduction011 from './Introduction011'
import Introduction012 from './Introduction012'

const Wrapper = styled.article`
  position: relative;
  margin-bottom: 30px;
  .p-txt-date {
    margin-top: -10px;
    color: #ababab;
  }
  section {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    font-size: 16px;
    color: #505050;
    line-height: 1.6em;
    h1 {
      color: #414141;
      font-size: 22px;
      line-height: 1.4em;
      background: #f5f5f5;
      border-radius: 3px;
      position: relative;
      width: 100%;
      padding: 5px 5px 5px 20px;
      margin-bottom: 30px;
      margin-top: 10px;
      &:before {
        content: '';
        position: absolute;
        width: 10px;
        background: #77d7c9;
        left: 0;
        top: 0;
        border-radius: 3px 0 0 3px;
        height: 100%;
      }
    }
    h2 {
      color: #414141;
      font-size: 22px;
      line-height: 1.4em;
      background: #ffffff;
      border-radius: 3px;
      position: relative;
      width: 100%;
      padding: 5px 5px 5px 20px;
      margin-top: 10px;
      margin-bottom: 30px;
      &:before {
        content: '';
        position: absolute;
        width: 10px;
        background: #d8d8d8;
        left: 0;
        top: 0;
        border-radius: 3px 0 0 3px;
        height: 100%;
      }
    }
    h3 {
      color: #414141;
      font-size: 16px;
      line-height: 1.4em;
      background: #ffffff;
      width: auto;
      padding: 10px 0 8px;
      margin-top: -10px;
      margin-bottom: 20px;
      border-top: solid 2px #e5e5e5;
      border-bottom: solid 2px #e5e5e5;
    }
    p {
      padding-bottom: 30px;
      line-height: 2;
      position: relative;
      span {
        background: linear-gradient(transparent 60%, #ff6 60%);
        font-weight: 600;
      }
      .p-list-link {
        position: absolute;
        top: 6px;
        right: 2px;
        &:before {
          display: block;
          content: '';
          position: absolute;
          width: 0;
          height: 0;
          top: 7px;
          left: -14px;
          border-left: 8px solid #3ec7b3;
          border-top: 4px solid transparent;
          border-bottom: 4px solid transparent;
        }
      }
      &.txt-color-red {
        color: #ff6969;
      }
    }
    .link-div {
      width: 100%;
      display: flex;
      justify-content: left;
      padding-bottom: 30px;
      > div {
        width: 50px;
      }
      .link-p {
        width: calc(100% - 50px);
        .p-list-link {
          position: absolute;
          top: -3px;
          left: 30px;
          &:before {
            display: block;
            content: '';
            position: absolute;
            width: 0;
            height: 0;
            top: 11px;
            left: -14px;
            border-left: 8px solid #3ec7b3;
            border-top: 4px solid transparent;
            border-bottom: 4px solid transparent;
          }
        }
      }
    }
    img {
      width: 100%;
      height: auto;
      padding-bottom: 30px;
    }
  }
  ${BreakPoints.large} {
  }
`
type Props = {
  /** 広告の番号 */
  no: number
}

/**
 * コラム記事詳細：記事本体
 * @constructor
 */
const ColumnDetailInner0058 = (props: Props): JSX.Element => (
  <Wrapper>
    <ColumnH1 label="建設業の働き方改革！適用される内容や取り組むべきこととは？" />
    {/** TODO 川上申し送り事項：▼投稿日をここに表示 */}
    <p className="p-txt-date">2023.09.11</p>
    <section>
      {/** TODO 川上申し送り事項：▼記事投稿（？）で作成された内容がここに入る予定 */}
      <p>
        2024年4月1日からいよいよ建設業においても時間外労働上限規制が適用されます。これまで同業種や医師については適用が「猶予」されていましたが、5年の期間を経て猶予期間が終了します。そこで、適用される内容、建設業として行わなければならないこと等にフォーカスをあて解説します。
      </p>
      <img src="/images/column/details/c0058.jpg" alt="キンクラコラム画像58" />
      <h1 id="toc1">2024年4月1日から適用される内容</h1>
      <p>
        端的には時間外労働の<span>「上限規制」</span>
        が適用されることとなりますが、これは法律によって時間外労働として命じることができる上限が課されるということです。言い換えると
        <span>当該上限を超えた時間外労働が発覚した場合は違法</span>
        ということになります。そこで労働基準法第32条を確認しましょう。同条文には労働時間（拘束時間から休憩時間を除く）は1日8時間まで、1週間40時間までと規定されています。ただし、36協定を締結することで1か月45時間、年360時間までであれば時間外労働を命じることが可能と解されます。また、臨時的な事由がある場合、特別条項と言い、同条項を労使合意の元に締結することで年間720時間、単月100時間未満（休日労働を含む）、複数月80時間以下（休日労働を含む）までであれば時間外労働を命じることが可能となります。ただし、
        <span>時間外労働が月45時間を超えることができるのは年6回まで</span>
        であり、一般職でも誤解が多い部分として、「複数月80時間以下」とは、2か月平均でも６か月平均でも80時間以下でなければならないということです。すなわち、2か月経過時点で適法状態であっても6か月経過時には違法状態になってしまう可能性もあるということです。
        <br />
        <br />
        時間外労働の上限規制は既に2019年4月1日以降、企業規模要件（大企業から先行的に実施）を経て、一般職については適用されていますが、労務管理の煩雑さ等を勘案し、建設業においては猶予期間が設けられていました。これは一定の猶予期間を設けられたということは、裏返すと上限規制対応に向けて（5年という期間を活用し）準備できていて然るべきとの見方をされることもあります。
      </p>

      <h1 id="toc2">適用される前に建設業者が行わなければならないこと</h1>
      <h2 id="toc2-1">業務の整理</h2>
      <p>
        そもそも労働時間とは
        <span>過去の労働判例上も使用者の指揮命令下に置かれた時間</span>
        と解されます。よって、業務とは言い難い単なる同僚同士の談笑や職場内に滞留している時間まで画一的に労働時間としてカウントしてしまうと法令順守が難しくなる可能性もあるでしょう。もちろん、同僚同士の談笑が職場の結束力を生み、長期的に生産性を向上させるとの指摘もありますが、まずは
        <span>法令順守に向けて一度は精査</span>
        することが適切です。そこで、具体的にやるべきこととしては、
        <span>業務としての線引きと必要ではない業務の抜粋</span>ということです。
      </p>
      <h2 id="toc2-2">システムの導入</h2>
      <p>
        2つ目はシステムの導入です。もちろん少人数の事業所であればある程度の人数までアナログ管理も可能と考えますが、2024年4月1日以降、ある程度の時間外労働を余儀なくされる場合で、かつ、法令順守の観点から念のため特別条項を締結した場合を例に挙げます。この場合、繰り返しになりますが、
        <span>1か月は適法状態でも6か月目に違法状態</span>
        となる可能性もはらんでいますので、残業時間が予め設定した時間に到達した場合は
        <span>アラート機能が発動するなどシステム上の機能に頼る</span>
        ことが適切です。2024年4月1日以降は罰則付きの上限規制という背景を勘案しても、「知らないうちに超えてしまっていた」で済まない問題となります。また、システムの選定については実際に使用するのは従業員となりますので、継続的に使用してもらえるようお試し期間を活用するなど、視覚的に活用しやすいものを選定することが肝要です。また、サポートの充実度も考慮に含めて検討することがよいでしょう。
      </p>

      {/** ここに9パターンの広告設定する */}
      {props.no === 0 && <Introduction004 />}
      {props.no === 1 && <Introduction005 />}
      {props.no === 2 && <Introduction006 />}
      {props.no === 3 && <Introduction007 />}
      {props.no === 4 && <Introduction008 />}
      {props.no === 5 && <Introduction009 />}
      {props.no === 6 && <Introduction010 />}
      {props.no === 7 && <Introduction011 />}
      {props.no === 8 && <Introduction012 />}

      <h1 id="toc3">建設業の働き方改革の事例 など</h1>
      <h2 id="toc3-1">有給取得率の数値化</h2>
      <p>
        既に2019年4月1日以降、企業規模・業種を問わず、適用されている年10日以上の有給休暇付与対象者に対する年5日の取得義務化ですが、原則として、有給休暇取得日に残業は発生しないはずです。よって、班ごとや課ごとに有給休暇の取得率を公表し、積極的な取得を呼びかけるという取り組みです。また、取得率が著しく低い班や課の管理職についてはその事実についても人事評価に加えることで部下目線では、「上司に有給休暇の申請がしづらい」という風土も改善できたという事例です。
      </p>
      <h2 id="toc3-2">残業の事前承認制</h2>
      <p>
        一度残業体質に陥ってしまうと、そのことに気づくことが難しくなります。また、2024年4月1日以降は旧来と異なり（業務上必要であることには間違いないにせよ）法律によって残業時間に上限が課されますので、ある程度早い段階でその時点での
        <span>蓄積時間を把握しておく</span>ことが重要です。そのために、
        <span>
          管理者側からも部下の残業事前承認申請を契機に部下の残業時間を把握する仕組みを作っておく
        </span>
        ことが有用です。少なくとも申請がない状態でその月の最後にまとめて把握する管理体制よりは事前申請があることによって早い段階で気づくことができる可能性は高いと言えます。
      </p>

      <h1 id="toc4">最後に</h1>
      <p>
        建設業の働き方改革は一朝一夕にベストの体制が整うほど簡単な問題ではありません。早い段階で事業場ごとに個別の問題点を把握し、対応していくことが極めて重要です。
      </p>
    </section>
  </Wrapper>
)

export default ColumnDetailInner0058
